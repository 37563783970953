import { SuccessRateDynamicStoryblok } from 'lib/storyblok/types'

import { ClinicSectionHeader } from './ClinicSectionHeader'
import { Graph } from './Graph'

type Props = {
  block: SuccessRateDynamicStoryblok
}

export const SuccessRateDynamic = ({ block }: Props) => {
  const { header, graph } = block
  return (
    <>
      {header?.[0] && <ClinicSectionHeader block={header?.[0]} />}
      <section className="flex flex-col max-w-screen-desktop mx-auto px-5 md:px-20 pb-10 lg:pb-15 gap-10 md:gap-15">
        {graph && graph.map((g, i) => <Graph key={i} block={g} />)}
      </section>
    </>
  )
}
