import { Fragment, useState } from 'react'

import { Button } from 'components/ui/Button'
import { Icon } from 'components/ui/Icon'
import { Tooltip } from 'components/ui/Tootlip'
import {
  GraphColumnEntryStoryblok,
  GraphStoryblok,
  TextLinkStoryblok,
} from 'lib/storyblok/types'
import { getAnchorFromCmsLink } from 'lib/utils/content'

type Props = {
  block: GraphStoryblok
}

export const Graph = ({ block }: Props) => {
  return (
    <section className="flex flex-row justify-between gap-[110px] border-t border-dashed border-foreground/60 pt-8 md:pt-10">
      {block.variant === 'right' ? (
        <GraphInfo {...block} />
      ) : (
        <div className="hidden lg:block">
          <GraphColumns column={block.column} />
        </div>
      )}
      {block.variant === 'right' ? (
        <div className="hidden lg:block">
          <GraphColumns column={block.column} />
        </div>
      ) : (
        <GraphInfo {...block} />
      )}
    </section>
  )
}

type GraphInfoProps = Omit<GraphStoryblok, 'variant'>

const GraphInfo = ({
  description_graph,
  subtitle1_graph,
  subtitle2_graph,
  primary_button_text,
  primary_button_link,
  date_graph,
  source_graph,
  source_graph_link,
  source_tooltip_graph,
  links,
  column,
}: GraphInfoProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  )

  return (
    <div className="flex flex-col justify-between md:max-w-[550px]">
      <div className="flex flex-col gap-1 text-eighteen md:text-twenty font-light">
        <p className="mb-5">{description_graph}</p>
        {subtitle1_graph && (
          <div className="flex items-center gap-2">
            <div className="w-5 h-5 rounded-1.5 border-foreground/5 bg-[#E3E6E6]" />
            <p>{subtitle1_graph}</p>
          </div>
        )}
        {subtitle2_graph && (
          <div className="flex items-center gap-2">
            <div className="w-5 h-5 rounded-1.5 border-foreground/5 bg-accent-secondary" />
            <p>{subtitle2_graph}</p>
          </div>
        )}
      </div>

      <div className="lg:hidden my-10">
        <GraphColumns column={column} />
      </div>

      <div>
        {primary_button_text && primary_button_link && (
          <Button
            as="a"
            variant="outline"
            rel={getAnchorFromCmsLink(primary_button_link).rel}
            href={getAnchorFromCmsLink(primary_button_link).href}
            target={getAnchorFromCmsLink(primary_button_link).target}
            icon="arrow-right"
            iconPosition="right"
          >
            {primary_button_text}
          </Button>
        )}
        <div className="flex flex-col gap-2 text-fourteen md:text-sixteen font-light mt-4 md:mt-5">
          {date_graph && <p>{date_graph}</p>}
          {source_graph && (
            <div className="flex lg:items-center gap-1">
              <p>{source_graph}</p>
              {source_graph_link && (
                <a
                  href={getAnchorFromCmsLink(source_graph_link).href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-foreground underline"
                >
                  {source_graph_link}
                </a>
              )}
              {source_tooltip_graph && (
                <>
                  <div
                    ref={setReferenceElement}
                    onMouseEnter={() => setIsOpen(true)}
                    onMouseLeave={() => setIsOpen(false)}
                    className="relative hidden lg:flex items-center"
                  >
                    <Icon icon="info" size={16} />
                  </div>
                  <Tooltip
                    referenceElement={referenceElement}
                    isOpen={isOpen}
                    label={source_tooltip_graph}
                    className="max-w-[258px] text-center hidden lg:block"
                  />
                </>
              )}
            </div>
          )}
          <p className="lg:hidden">{source_tooltip_graph}</p>

          {links && links.length > 0 && (
            <div className="flex gap-2 whitespace-nowrap flex-wrap">
              {links.map((link: TextLinkStoryblok, index: number) => (
                <Fragment key={link._uid}>
                  {index === 1 && <p>·</p>}
                  <a
                    href={getAnchorFromCmsLink(link.link).href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-foreground underline"
                  >
                    {link.label}
                  </a>
                </Fragment>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

type GraphColumnsProps = {
  column: GraphColumnEntryStoryblok[]
}

const GraphColumns = ({ column }: GraphColumnsProps) => {
  const maxTFP = Math.max(...column.map((c) => Number(c.tfp_success_rate)))

  return (
    <div className="flex gap-2 md:gap-3 sm:max-w-[620px]">
      {column.map((c) => (
        <Column
          key={c._uid}
          column={c}
          maxTfpSuccess={maxTFP}
          columnLength={column.length}
        />
      ))}
    </div>
  )
}

type ColumnProps = {
  column: GraphColumnEntryStoryblok
  maxTfpSuccess: number
  columnLength: number
}

const Column = ({ column, maxTfpSuccess, columnLength }: ColumnProps) => {
  const { tfp_pregnancies_embryo, tfp_success_rate, nation_average, age_gap } =
    column

  const pregnanciesEmbryos = tfp_pregnancies_embryo?.split(/(?<=\/)/) || []
  const getHeight = (percentage: number) =>
    Number(((percentage * 309) / maxTfpSuccess).toFixed(2))
  const tfpHeight = getHeight(Number(tfp_success_rate))
  const nationalHeight = getHeight(Number(nation_average))

  return (
    <div
      className={`flex flex-col items-center gap-1 md:gap-2 justify-end w-[calc(100%/${columnLength})] sm:w-[93.33px]`}
    >
      <div className="flex flex-col">
        <div className="sm:hidden">
          {pregnanciesEmbryos.map((text, i) => (
            <p
              key={i}
              color="accent.secondary"
              className="w-full text-fourteen font-regular text-accent-secondary"
            >
              {text}
            </p>
          ))}
        </div>
        <p className="hidden sm:block text-fourteen font-regular text-accent-secondary">
          {tfp_pregnancies_embryo}
        </p>
        <div className="flex items-baseline gap-0.5">
          <p className="text-title-medium md:text-title-card font-regular text-accent-secondary">
            {tfp_success_rate}
          </p>
          <p className="text-sixteen md:text-title-small font-regular text-accent-secondary">
            %
          </p>
        </div>
      </div>
      <div
        className="relative flex flex-col w-full rounded-3 md:rounded-5 overflow-hidden"
        style={{ height: `${Math.max(tfpHeight, nationalHeight)}px` }}
      >
        <div
          className="absolute bottom-0 left-0 w-full bg-accent-secondary z-0"
          style={{ height: `${tfpHeight}px` }}
        />
        <div
          className="absolute bottom-0 left-0 w-full bg-[#E3E6E6] z-10"
          style={{ height: `${nationalHeight}px` }}
        />
        <div className="absolute bottom-1 md:bottom-2 self-center flex items-baseline z-20 gap-0.5">
          <p className="text-fourteen md:text-sixteen font-light">
            {nation_average}
          </p>
          <p className="text-[10px] md:text-twelve font-regular">%</p>
        </div>
      </div>
      <p className="text-fourteen md:text-sixteen font-light">{age_gap}</p>
    </div>
  )
}
